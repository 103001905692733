<template>
  <div class="my">

    <nav class="navbar navbar-light fixed-top navbar-expand-lg navwhite">
      <div class="container">
        <a class="navbar-brand my-1">
          <img src="../../public/img/logo.png" class="logo-sm-height" height="40">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <!-- <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="" >首页</a>
            </li>
          </ul> -->
          <span class="navbar-text">
							<a class="gongzuotai">工作台</a>
							<a @click="logout" class="tuchu">退出登录</a>
            <!-- <span class="reglogbox">
              <a href="" >登录</a>
                /
              <a href="" >注册</a>
            </span> -->
							</span>
        </div>
      </div>
    </nav>

    <!--占位导航栏-->
    <div style="height:80px;" class="head-sm-height"></div>
    <!-- <el-button @click="logout" type="danger">退出登录</el-button> -->

    <div class="kanbanpage">
      <div class="workcate">
        <div class="container">
          <a href="" class="active">服务看板</a>
          <a href="">应用中心</a>
          <a href="">工单管理</a>
          <a href="">团队管理</a>
        </div>
      </div>
      <div class="kanban">
        <div class="container">
          <div class="kanbanbanner">
            <a @click="zhenduan">
              <img src="../../public/img/kanban-banner.jpg" width="100%"/>
            </a>
          </div>
          <div class="row">
            <!-- <div class="col-md-8">
              <div class="daiban">
                								<h4>代办事项</h4>
                								<div class="row">
                									<div class="col-md-4">
                										<img src="../../public/img/daiban-ico1.png"/>
                										<div class="daiban-rt">
                											<h5>待处理告警</h5>
                											<h3>0</h3>
                										</div>
                									</div>
                									<div class="col-md-4">
                										<img src="../../public/img/daiban-ico2.png"/>
                										<div class="daiban-rt">
                											<h5>待支付订单</h5>
                											<h3>0</h3>
                										</div>
                									</div>
                									<div class="col-md-4">
                										<img src="../../public/img/daiban-ico3.png"/>
                										<div class="daiban-rt">
                											<h5>待处理工单</h5>
                											<h3>0</h3>
                										</div>
                									</div>
                								</div>
                <div class="row">
                  <div class="col-md-6 statusItem" @click="getWorkbenchInfo('已交付')">
                    <span style="color: #F93B07;">{{ statusNum.amount || 0 }}</span>
                    <p>已交付</p>
                  </div>
                  <div class="col-md-6 statusItem" @click="getWorkbenchInfo('未交付')">
                    <span style="color: #FCC002;">{{ statusNum.processing || 0 }}</span>
                    <p>未交付</p>
                  </div>
                </div>
              </div>
            </div> -->
						<div class="col-md-8">
						  <div class="yigouxm">
						    <h4>已购应用/服务</h4>
						    <div class="yigouxm-con">
						      <div class="yigouxmli">
						        <div class="yigouxmli-l">
											<img src="../../public/img/szhzx-l.jpg"/>
										</div>
										<div class="servtit-mb">
											<img src="../../public/img/szhzx-l-mb.jpg" width="100%" height="32"/>
										</div>
						        <div class="yigouxmli-r">
											<ul>
						            <li>
						              <div @click="zhenduan">
						                <img src="../../public/img/szhzx-item1.jpg"/>
						                <div class="title">中小企业数字化转型水平评测</div>
						              </div>
						            </li>
												<!-- <li>
												  <div>
												    <img src="../../public/img/szhzx-item2.jpg"/>
												  </div>
												</li>
												<li>
												  <div>
												    <img src="../../public/img/szhzx-item3.jpg"/>
												  </div>
												</li>
												<li>
												  <div>
												    <img src="../../public/img/szhzx-item4.jpg"/>
												  </div>
												</li>
												<li>
												  <div>
												    <img src="../../public/img/szhzx-item5.jpg"/>
												  </div>
												</li>
												<li>
												  <div>
												    <img src="../../public/img/szhzx-item6.jpg"/>
												  </div>
												</li>
												<li>
												  <div>
												    <img src="../../public/img/szhzx-item7.jpg"/>
												  </div>
												</li>
												<li>
												  <div>
												    <img src="../../public/img/szhzx-item8.jpg"/>
												  </div>
												</li> -->
						          </ul>
						        </div>
						      </div>
						      <div class="yigouxmli">
						        <div class="yigouxmli-l">
											<img src="../../public/img/szhkb-l.jpg"/>
										</div>
										<div class="servtit-mb">
											<img src="../../public/img/szhkb-l-mb.jpg" width="100%" height="32"/>
										</div>
						        <div class="yigouxmli-r">
											<ul>
						            <li>
						              <a href="http://datacenter.winzome.com/display_v2?kb&513D421A1CC7ACE5851BA79405FDF805&/datashow/enterprise" target="_blank">
						                <img src="../../public/img/szhkb-item1.jpg"/>
						                <div class="title">企业运营看板</div>
						              </a>
						            </li>
						            <li>
						              <a href="http://datacenter.winzome.com/display_v2?kb&513D421A1CC7ACE5851BA79405FDF805&/datashow/manufacture" target="_blank">
						                <img src="../../public/img/szhkb-item2.jpg"/>
						                <div class="title">生产执行看板</div>
						              </a>
						            </li>
						            <li>
						              <a href="http://datacenter.winzome.com/display_v2?kb&513D421A1CC7ACE5851BA79405FDF805&/datashow/safework" target="_blank">
						                <img src="../../public/img/szhkb-item3.jpg"/>
						                <div class="title">安全生产看板</div>
						              </a>
						            </li>
						            <li>
						              <a href="http://datacenter.winzome.com/display_v2?kb&513D421A1CC7ACE5851BA79405FDF805&/datashow/greenkb" target="_blank">
						                <img src="../../public/img/szhkb-item4.jpg"/>
						                <div class="title">能源管控看板</div>
						              </a>
						            </li>
						            <!-- <li>
						              <a href="http://datacenter.winzome.com/display_v2?kb&513D421A1CC7ACE5851BA79405FDF805&/datashow/manufacture" target="_blank">
						                <img src="../../public/img/szhkb-item5.png"/>
						                <div class="title">质量管理看板</div>
						              </a>
						            </li> -->
						          </ul>
						        </div>
						      </div>
						
						    </div>
						  </div>
						</div>
            <div class="col-md-4">
              <div class="userbx">
                <div class="userbxmid">
                  <!-- <img :src="userInfo.avatar"/> -->
                  <img v-if="userInfo.avatar" :src="userInfo.avatar"/>
                  <img v-else src="../../public/img/user.png"/>
                  <div class="userbx-rt">
                    <h3>{{ userInfo.nickname }}</h3>
                    <h5>{{ userInfo.company_name }}</h5>
										<h5>账号：{{ userInfo.mobile }}</h5>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-8">
              <div class="daiban daiban2">
                <div v-if="workbenchListHandle == ''">暂无数据</div>
                <div v-else>
                  <div v-for="item in workbenchListHandle" :key="item.id">
                    <div class="contractNo">
                      <svg class="icon hetongIcon" aria-hidden="true">
                        <use xlink:href="#icon-hetong"></use>
                      </svg>
                      <div class="ctNo">合同号: {{ item.contractNo }}</div>
                    </div>
                    <el-row :gutter="15" class="listMain">
                      <el-col :span="16" class="orderNo">{{ item.type_name }}</el-col>
                      <el-col :span="8">
                        <div class="filedBtn" @click="getFiled(item.id)">附件</div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="15">
                      <el-col :span="16">
                        <div>单号： {{ item.workbench_id }}</div>
                        <div class="time">开通日期: {{ item.starttime }}</div>
                        <div class="time">到期日期: {{ item.endtime }}</div>
                      </el-col>
                      <el-col :span="8">
                        <div style="width: 90px;float: right;text-align: center;margin: 5px 0 0;">
													{{ item.status }}
												</div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        详情: {{ item.content }}
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md-4">
              <div class="appliserv">
                <div class="row">
                  <div class="col-md-4 appitem">
                    <img src="../../public/img/appli-ico1.png"/>
                    <h5>订阅应用</h5>
                    <h5>{{ statusNum.amount || 0 }}款</h5>
                  </div>
                  <div class="col-md-4 appitem">
                    <img src="../../public/img/appli-ico2.png"/>
                    <h5>API服务</h5>
                    <h5>0款</h5>
                  </div>
                  <div class="col-md-4 appitem">
                    <img src="../../public/img/appli-ico3.png"/>
                    <h5>团队人数</h5>
                    <h5>0款</h5>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-md-4">
              <div class="noticeann">
                <h4>通知公告 <span @click="toList">更多 ></span> </h4>
                <ul>
                  <li v-for="item in newList" :key="item.id" @click="newDetail(item.id)">
                    <div>{{ item.title }}</div>
                    <span>{{ item.createtime | formattedDate }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="附件"
        :visible.sync="dialogVisible"
        width="35%">
      <ul class="dialogMain">
        <li v-for="(item, index) in filedList" :key="item.id" @click="downloadFile(item.url)">{{index+1}} . {{ item.name }}</li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'my',
  data() {
    return {
      userInfo: [],
      mobile: '',
      statusNum: {},
      workbenchListHandle: [],
      filedList: [],
      dialogVisible: false,
      newList: [],
    }
  },
  components: {},
  filters: {
    formattedDate(val) {
      const date = new Date(val * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  }, 
  mounted() {
    const mobile = localStorage.getItem('mobile');
    if (mobile) {
      this.getUserInfo(mobile);
      this.getWorkbenchStatusNum(mobile);
      this.mobile = mobile;
      this.getWorkbenchInfo("已交付");
    } else {
      this.$router.push('/login');
    }
    this.getNews();
  },
  methods: {
    // 退出登录
    logout() {
      this.$confirm('是否退出当前账号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        localStorage.removeItem('mobile');
        localStorage.removeItem('uscc');
        localStorage.removeItem('company');
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
        this.$router.push('/login');
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 根据手机号获取当前用户所有信息
    getUserInfo(mobile) {
      this.$axios.get('/http/api/wanlshop/user/getUser', {
        params: {mobile: mobile},
      }).then(res => {
        this.userInfo = res.data.data;

      }).catch((err) => {
        console.log(err)
      })
    },
    // 中小企业数字化转型水平评测
    zhenduan() {
      this.$router.push({
        path: '/zhenduan',
        // params: {
        //   userName: this.userInfo.username,
        //   mobile: this.userInfo.mobile
        // }
      });
    },
    // 各个状态数量
    getWorkbenchStatusNum(mobile) {
      this.$axios.post('/http/api/wanlshop/workbench/workbenchStatusNum', {
        phone: mobile
      }).then(res => {
        this.statusNum = res.data.data;
      }).catch((err) => {
        console.log(err)
      })
    },
    // 工单列表
    getWorkbenchInfo(status) {
      this.workbenchListHandle = [];
      if (!status) {
        status = '已交付';
      }
      this.$axios.post('/http/api/wanlshop/workbench/workbenchList', {
        phone: this.mobile,
        status: status
      }).then(res => {
        this.applyWorkbenchList(res.data.data);
      }).catch((err) => {
        console.log(err)
      })
    },
    // 订单号相同的工单拼成新数组
    applyWorkbenchList(workbenchList) {
      let tempArr = [];
      workbenchList.forEach((item, index) => {
        if (tempArr.indexOf(workbenchList[index].order_id) == -1) {
          this.workbenchListHandle.push({
            order_id: workbenchList[index].order_id,
            contractNo: workbenchList[index].contractNo,
            handle: workbenchList[index],
            type_name: workbenchList[index].type_name,
            workbench_id: workbenchList[index].workbench_id,
            status: workbenchList[index].status,
            id: workbenchList[index].id,
            starttime: workbenchList[index].starttime,
            endtime: workbenchList[index].endtime,
            content: workbenchList[index].content,
          });
          tempArr.push(workbenchList[index].order_id);
        } else {
          this.workbenchListHandle.forEach((it, i) => {
            if (this.workbenchListHandle[i].order_id == workbenchList[index].order_id) {
              this.workbenchListHandle[i].handle.push(workbenchList[index]);
            }
          })
        }
      })
    },
    // 附件
    getFiled(id){
      this.dialogVisible = true;
      this.$axios.post('/http/api/wanlshop/workbench/workbenchFiled', {
        id: id
      }).then(res =>{
        this.filedList = res.data.data;
      })
    },
    // 下载 pdf
    downloadFile(url){
      window.open(url);
    },
    // 最新公告
    getNews(){
      this.$axios.post('/http/api/wanlshop/article/newsList', {
        "flag": "recommend",
        "id": 144
      }).then(res => {
        this.newList = res.data.data;
      })
    },
    // 新闻列表
    toList(){
      this.$router.push({path:'/newList'})
    },
    // 公告详情
    newDetail(id){
      this.$router.push({path:'/newDetail', query:{id: id}});
    }
  }
}
</script>
<style scoped lang="scss">
.my {
  .kanbanpage {
    .kanban {
      .daiban2{
        height: auto;
        .time{
          font-size: 13px;
          color: #9b9b9b;
        }
      }
      .daiban {
        .statusItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 20px;

          span {
            font-size: 32px;
            font-weight: bold;
          }

          p {
            font-size: 18px;
          }
        }
        .contractNo{
          display: flex;
          align-items: center;
          justify-content: start;
          .hetongIcon{
            width: 24px;
            height: 24px;
            color: #2c57b6;
          }
          .ctNo{
            font-weight: bold;
            margin-left: 5px;
          }
        }
        .listMain{
          margin-top: 12px;
          .orderNo{
            font-weight: bold;
          }
          .filedBtn{
            color: #0081ff;float: right;
            background: #e9e9e9;
            padding: 5px 0;
            border-radius: 4px;
            font-size: 14px;
            width: 90px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .dialogMain{
    li{
      line-height: 24px;
      cursor: pointer;
    }
  }
	.servtit-mb{
		display: none;
	}
}
@media only screen and (max-width:768px) {
	.my{
		.navbar-brand{
			padding: 5px 0
		}
		.navbar-toggler{
			display: none;
		}
		.collapse{
			display: block;
		}
		.navbar-text{
			right: 0;top: -10px
		}
		.head-sm-height{
			height: 60px !important;
		}
		.kanbanpage .workcate a{
			padding: 0 10px;margin-right: 0;
		}
		.kanbanbanner{
			overflow: hidden;
			margin: 0 -15px;width: calc(100% + 30px);
			img{
				width: 150%;
			}
		}
		.daiban{
			height: auto;
			.statusItem{
				width: 50%;float: left;
				p{
					font-size: 16px !important;
				}
			}
		}
		.userbx{
			display: none;
		}
		.appliserv{
			.appitem{
				width: 33.33%;float: left;
				h5{
					font-size: 14px;
				}
				img{
					width: 50px;
				}
			}
		}
		.yigouxm{
			margin: 0 -15px 20px;width: calc(100% + 30px);
			padding: 10px;
			h4{
				display: none;
			}
			.yigouxm-con{
				.servtit-mb{
					display: block;margin: 10px 0 5px;
				}
				.yigouxmli-l{
					display: none;
				}
				.yigouxmli-r{
					width:100%;padding-left: 0;
					ul{
						width: 102%;margin: 0 -1%;
					}
					li{
						margin-bottom: 5px;
					}
				}
			}
		}
	}
}
</style>
