<template>
  <!-- 页面布局 -->
  <div>
    <!-- Banner 区域 -->
    <div class="banner">
			<img src="../../../src/assets/banner.jpg" width="100%"/>
		</div>

    <!-- 内容区 -->
    <div class="content">
      <h2>中小企业数字化水平评测指标（2024年版）</h2>
      <p>为进一步助力中小企业数字化转型，《中小企业数字化水平评测指标（2024年版）》
			延续2022年版整体架构，从数字化基础、经营、管理、成效四个维度综合评估中小企业
			化发展水平，并对评测方式进行了调整优化，其中，数字化基础、管理和成效三个维度
			采用评分的方式确定等级，数字化经营部分用场景等级判定的方式确定等级。
			判断标准如下：</p>

			<table>
				<tr>
					<th>等级</th>
					<th>数字化基础、管理及成效</th>
					<th>数字化经营应用场景</th>
				</tr>
				<tr>
					<td>一级(初始级)</td>
					<td>≥20分</td>
					<td>不少于6个应用场景（其中不少于3个约束性场景）等级需达到一级</td>
				</tr>
				<tr>
					<td>二级(规范级)</td>
					<td>≥40分</td>
					<td>不少于6个应用场景（其中不少于3个约束性场景）等级需达到二级</td>
				</tr>
				<tr>
					<td>三级(集成级)</td>
					<td>≥60分</td>
					<td>不少于8个应用场景（其中不少于5个约束性场景）等级需达到三级</td>
				</tr>
				<tr>
					<td>四级(协同级)</td>
					<td>≥80分</td>
					<td>不少于10个应用场景（其中不少于6个约束性场景）等级需达到四级</td>
				</tr>
			</table>

      <p>
        广大中小企业可根据自身实际情况通过此平台完成线上自测，也可发起线下诊断需求，
				评估师将通过人员访谈、问卷调查、系统演示、现场勘查等方式，为中小企业提供数字
				化水平评估诊断，助力中小企业明确实施路径，加快转型进程。</p>

      <!-- 按钮区 -->
      <div class="buttons">
        <el-button type="primary" size="large" @click="online">自测答题</el-button>
        <el-button type="success" size="large" @click="downline">线下评测</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      isAnswer: false
    }
  },
  mounted() {
    const currentURL = window.location.href;
    const splitURL = currentURL.split('?');
    if (splitURL.length > 1) {
      const queryString = splitURL[1];
      const paramPairs = queryString.split('&');
      for (let i = 0; i < paramPairs.length; i++) {
        const [paramName, paramValue] = paramPairs[i].split('=');
        if (paramName === 'mobile') {
          localStorage.setItem('mobile', paramValue);
        }else if (paramName === 'xsId') {
          localStorage.setItem('xsId', paramValue);
        }
      }
    }
    let mobile = localStorage.getItem('mobile');
    if (mobile) {
      this.getIsAnswer(mobile);
    } else {
      this.$router.push('/login');
    }
  },
  methods: {
    getIsAnswer(mobile){
      this.$axios.get('/zhenduan/api/yexam/exam/isAnswer', {
        params: {mobile: mobile},
      }).then(res => {
        if(res.data.code == 1){
          this.isAnswer = true;
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    online(){
      if(this.isAnswer){
        this.$router.push('/success')
      }else{
        this.$router.push({
          path: '/online',
          query:{}
        });
      }
    },
		downline(){
			this.$router.push({
			  path: '/downline',
			  query:{}
			});
		}
  }
}
</script>

<style scoped lang="scss">
/* 全局样式 */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

/* Banner 样式 */
.banner {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影 */
  margin-bottom: 40px;
}

.banner h1 {
  color: #ffffff;
  font-size: 36px;
  z-index: 2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* 内容区样式 */
.content{
	width: 1200px;margin: 0 auto;padding-bottom: 60px;
	table{
		width: 100%;

		tr{
			font-size: 16px;
			th{
				padding: 10px;text-align: center;
				font-weight: bold;border: 1px solid #ddd;
			}
			td{
				padding: 10px;text-align: center;
				border: 1px solid #ddd;
			}
		}
	}

}

.content h2 {
  font-size: 32px;
  margin: 60px 0 40px;
  color: #333;text-align: center;font-weight: bold;
}

.content p {
  font-size: 16px;
  line-height: 1.8;
  margin: 20px 0;
  color: #555;
	text-indent: 2em;
}

/* 按钮区样式 */
.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.el-button {
  transition: all 0.3s ease;
  border-radius: 5px;
	font-size: 20px;padding: 15px 40px;
}

/* 按钮 hover 效果 */
.el-button:hover {
  transform: translateY(-2px);
}

.el-button--primary {
  background-color: #409eff;
  border-color: #409eff;
}

.el-button--success {
  background-color: #67c23a;
  border-color: #67c23a;
}

.el-button--primary:hover {
  background-color: #66b1ff;
}

.el-button--success:hover {
  background-color: #85ce61;
}
@media only screen and (max-width:768px) {
	.content{
		width: 100%;padding: 0 15px 50px;
		h2{
			font-size: 24px;
		}
		p{
			font-size: 14px;
		}
		table{
			tr{
				th,td{
					font-size: 14px;
				}
				th:first-child{
					width: 20%;
				}
			}
		}
		.el-button{
			font-size: 16px;padding: 10px 20px;
		}
	}

}
</style>
